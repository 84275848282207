/* Website color pallete: 
Primary Color:#6ac1b7;
Secondary Color:#
Secondary light color:#79c7be
Secondary dark color:#0a9695;
Warning color: #ffc107;
Footer color:#1d242d
Text Color: #666;
CTA color: #FDA14F
#E67E22


Font-sizing: 16 18 20 22 24 ....  42 44 46 48 50 52
icons-size: 
Font-family : "Roboto",sans-serif
Weights : 300 400 500 700;
line-height: 0px;
letter-spacing:0px;

*/

* {
  margin: 0px;
  padding: 0px;
}

html {
  font-size: 62.5%;
  font-family: "Roboto", sans-serif;
  font-display: swap;
}

body {
  box-sizing: border-box;
}
